var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('RowWrap', {
    class: [{
      'is-empty': _vm.mTrips.length === 0
    }]
  }, [_c('Row', {
    key: -1,
    staticClass: "Row-Header",
    attrs: {
      "parentHover": false,
      "items": {
        row: _vm.header
      },
      "index": -1
    },
    on: {
      "onClickColumn": function onClickColumn(e) {
        return _vm.handleHeaderClick(e);
      }
    }
  }), _vm._l(_vm.mTrips, function (trip, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "isDropdown": "",
        "parentHover": true,
        "items": trip,
        "index": i
      }
    });
  }), _vm.mTrips.length === 0 ? _c('EmptyState') : _vm._e()], 2), _vm.mTrips.length >= 30 && _vm.mTrips.length < _vm.trips.length ? _c('Button', {
    staticClass: "Button--LoadMore",
    on: {
      "onClick": _vm.handleLoadMore
    }
  }, [_vm._v(" Mehr Laden ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }